import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { customerSvc } from '../../../services';
import CustomerModal from "./customerModal";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import ViewDetailsModal from "../../../components/modal/viewDetails/viewDetails";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../components/button/actionButton";
import SearchBar from "../../../components/common/searchbar/searchBar";





const CustomerLists: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [visible, setVisble] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [values, setValues] = useState<any>('')
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar

  const [t] = useTranslation()
  
  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'first_name', header: 'First Name' },
    // { field: 'middle_name', header: 'Middle Name' },
    { field: 'last_name', header: 'Last Name' },
    { field: 'email', header: 'Email', type:'email' },
    { field: 'phone', header: 'Phone' },
  ];


  const customerData = () => {
    customerSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.customer);
      settotalRecords(res.data.recordcount);
    });
  };
  useEffect(() => {
    customerData()
  }, [limit, page]);
  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };


  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };
  const deleteRecord = (i: string) => {
    customerSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Customer deleted successfully');
        customerData();
      } else {
        toast.error('Failed to delete customer');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    customerSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Customer deleted successfully');
        customerData();
      } else {
        toast.error('Failed to delete customer');
      }
    });
  }

  const details = (id: string) => {
    customerSvc.get(id).then(res => {
      setValues(res.data);
    });
    setviewDetails(true);
  }

  const customerDetails: any = {
    "Customer Id": values.sl_no ? values.sl_no : "N/A",
    "Name": values ? `${values.first_name} ${values.middle_name} ${values.last_name}` : "N/A",
    "Email": values.email ? values.email : "N/A",
    "Mobile": values.phone ? values.phone : "N/A",    
    "Address Name": values?.address?.address_name || "N/A",
    "City": values?.address?.city || "N/A",
    "District": values?.address?.district || "N/A",
    "State": values?.address?.state || "N/A",
    "Pin Code": values?.address?.pin_code || "N/A",
    "Country": values?.address?.country || "N/A",

  };
  const permissionList = useSelector((state: any) => state.permissions.permissions);


  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );
  const extraAction = ({ rowData }: any) => {
    const hasViewPermission = permissionList && permissionList.includes('pages.customer.get');
    const hasUpdatePermission = permissionList && permissionList.includes('pages.customer.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.customer.delete');
    return <>
      { hasViewPermission && (<ActionButton  label={t("View")+''} icon="pi pi-eye"  className="p-button-secondary" onClick={() => { details(rowData._id) }} />)}
      { hasUpdatePermission && (<ActionButton  label={t("Edit")+''} icon="pi pi-pencil" className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      { hasDeletePermission && (<ActionButton  label={t("Delete")+''}icon="pi pi-trash" className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };
  // Implement Search bar 
  const handleSearch = () => {
    customerSvc.serchCustomer(searchQuery).then(res => {
      if (res) {
        console.log(res.data)
        setTableData(res.data.customer);
      }
    });

  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value === '') {
      customerData()
    }
  };
  const CustomerSearch = () => {

    return (
      <React.Fragment>
        <SearchBar
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
        />
      </React.Fragment>
    );
  };
  
  return (
    <>
      <DataGrid tittle={t("Customers")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={undefined} searchBar={CustomerSearch} />
      <CustomerModal id={id} setVisible={setVisble} visible={visible} updateTableData={customerData} />
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>
      {viewDetails && <ViewDetailsModal setVisible={setviewDetails} visible={viewDetails} details={customerDetails} tittle={customerDetails.Name} tableData={undefined} tableDataColumns={undefined} extraAction={undefined} />}
    </>
  );
};

export default CustomerLists;