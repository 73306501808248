import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { Steps } from 'primereact/steps';
import { FieldArray, Form, Formik, getIn } from "formik";
import { itemsSave } from "../../../validations";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { Dropdown } from "primereact/dropdown";
import { categorySvc, itemsSvc } from '../../../services'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CancelButton from "../../../components/button/cancelButton";
import SaveButton from "../../../components/button/saveButton";
const ItemsModal = ({ setVisible, visible = false, id, updateTableData }: IItemsModal) => {
    const ini = {
        tool_name: '',
        category: '',
        short_description: '',
        measurements: [{
            magnitude: null,
            value: null,
            unit: null
        }]
    };
    const [stage, setStage] = useState(0);
    const [itemId, setItemId] = useState(id);
    const [initialValues, setInitialValues] = useState(ini);
    const [categories, setCategories] = useState<any>('')
    const [magnitudeType, setMagnitudeType] = useState<any[]>([]);
    // const [imageFile, setImageFile] = useState<File | null>(null);

    const [t] = useTranslation()

    const items = [
        {
            label: 'Basics'
        },
        {
            label: 'Measurements'
        }
    ];





    const unit_type = [{ label: 'M', value: 'm' },
    { label: 'CM', value: 'cm' },
    { label: 'INCH', value: 'inch' },
    { label: 'FEET', value: 'feet' },
    { label: 'KG', value: 'kg' },];

    useEffect(() => {
        itemsSvc.getAvailableMagnitudes().then(res => {
            const options = res.data.map((item: { name: any; value: any; }) => ({
                name: item.name,
                value: item.value,
            }));
            setMagnitudeType(options);
        });
    }, []);


    const onClose = () => {
        setVisible(false);
        setItemId(undefined);
        setInitialValues(ini);
        setStage(0);
    };
    const onSubmit = async (e: any, actions: any) => {
        // const payload = { ...e, imageUrl: imageFile }
        // console.log(payload)
        if (itemId) {
            await itemsSvc.update(itemId, e);
            toast.success("Updated");
            updateTableData();
            onClose();

        }
        else {
            await itemsSvc.save(e);
            toast.success("Created");
            updateTableData();
            onClose();
        }
        actions.setSubmitting(false);
    };
    useEffect(() => {
        categorySvc.getAll().then(res => {
            const options = res.data.map((item: any) => ({
                name: item.category_name,
                value: item._id,
            }));
            setCategories(options);
        });
    }, []);

    useEffect(() => {
        setItemId(id);
        if (id && visible) {
            itemsSvc.get(id).then(res => setInitialValues({ ...res.data, }));
        }
    }, [id, visible]);


    return (
        <Dialog header={!id ? t("Create Item") as string : t("Update Item") as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onClose}>
            <Steps model={items} className="my-3" activeIndex={stage} />
            <Formik enableReinitialize initialValues={initialValues} validationSchema={itemsSave} onSubmit={onSubmit}>
                {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (
                        <Form>
                            {stage === 0 && <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText type="text" id="tool_name" {...getFieldProps(('tool_name'))} name='tool_name' />
                                        <label htmlFor="Item Name"  >Item Name</label>
                                    </span>
                                    {touched.tool_name && errors.tool_name && <ErrorMessage message={errors.tool_name} id="tool_name" />}

                                </div>

                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label ">
                                        {/* <Dropdown options={category} id={`category`} value={values.category} onChange={(e) => { setFieldValue(`category`, e.value); }} name={`category`} /> */}
                                        <Dropdown options={categories} {...getFieldProps(('category'))} value={values.category}
                                            onChange={(e) => setFieldValue('category', e.value)} optionLabel="name"
                                            optionValue="name" />

                                        <label htmlFor="category">Category</label>
                                    </span>
                                    {touched.category && errors.category && <ErrorMessage message={errors.category} id="category" />}
                                </div>

                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText id="short_description" {...getFieldProps(('short_description'))} name='short_description' />
                                        <label htmlFor="short_description">Short Description</label>
                                    </span>
                                    {touched.short_description && errors.short_description && <ErrorMessage message={errors.short_description} id="short_description" />}
                                </div>


                                {/* <div className="field col-12 md:4">
                                    <label htmlFor="image">Upload Image</label>
                                    <input type="file" accept="image/*" onChange={(e) => setImageFile(e.target.files?.[0] || null)} />
                                </div>

                                {imageFile && (
                                    <div className="field col-12 md-4">
                                        <img src={URL.createObjectURL(imageFile)} alt="Selected" style={{ maxWidth: '100px' }} />
                                    </div>
                                )} */}



                            </div>}


                            {
                                stage === 1 &&
                                <FieldArray
                                    name="measurements"
                                    render={arrayHelpers => (
                                        <>
                                            {
                                                values.measurements.map((val, index) => (
                                                    <div className="grid p-fluid mt-3" key={index}>
                                                        <Divider align="left">
                                                            <div className="inline-flex align-items-center">
                                                                <i className="pi pi-user mr-2"></i>
                                                                <b>Measurements {index + 1}</b>
                                                            </div>
                                                        </Divider>

                                                        <div className="field col-12 md:col-4">
                                                            <span className="p-float-label">
                                                                {/* <Dropdown options={magnitudeType} id={`measurements.${index}.magnitude`} value={values.measurements[index].magnitude} onChange={(e) => { setFieldValue(`measurements.${index}.magnitude`, e.value); }} name={`measurements.${index}.magnitude`} /> */}
                                                                <Dropdown
                                                                    options={magnitudeType}
                                                                    {...getFieldProps(`measurements[${index}].magnitude`)}
                                                                    value={values.measurements[index].magnitude}
                                                                    onChange={(e) => setFieldValue(`measurements[${index}].magnitude`, e.value)}
                                                                    optionLabel="name"
                                                                    optionValue="value"
                                                                />
                                                                <label htmlFor={`measurements.${index}.magnitude`}>Magnitude</label>
                                                            </span>
                                                            {errors.measurements?.at(index) && <ErrorMessage message={getIn(errors.measurements?.at(index), 'magnitude')} id={`measurements.${index}.magnitude`} />}

                                                        </div>

                                                        <div className="field col-12 md:col-4">
                                                            <span className="p-float-label">
                                                                <InputText id={`measurements.${index}.value`}  {...getFieldProps((`measurements.${index}.value`))} name={`measurements.${index}.value`} keyfilter="int" />
                                                                <label htmlFor={`measurements.${index}.value`}>Value</label>
                                                            </span>
                                                            {errors.measurements?.at(index) && <ErrorMessage message={getIn(errors.measurements?.at(index), 'pin_code')} id={`measurements.${index}.pin_code`} />}
                                                        </div>
                                                        <div className="field col-12 md:col-4">
                                                            <span className="p-float-label">
                                                                <Dropdown options={unit_type} id={`measurements.${index}.unit`} value={values.measurements[index].unit} onChange={(e) => { setFieldValue(`measurements.${index}.unit`, e.value); }} name={`measurements.${index}.unit`} />
                                                                <label htmlFor={`measurements.${index}.unit`}>Unit</label>
                                                            </span>
                                                            {errors.measurements?.at(index) && <ErrorMessage message={getIn(errors.measurements?.at(index), 'unit')} id={`measurements.${index}.unit`} />}
                                                        </div>
                                                        <div className="field col-12 md:col-4 ">
                                                            <Button type="button" icon="pi pi-plus" className="p-button-primary mr-2" aria-label="add" onClick={() => arrayHelpers.push({ // Push a new empty address object
                                                                magnitude: '',
                                                                value: '',
                                                                unit: ''
                                                            })} hidden={values.measurements.length > index + 1} />
                                                            <Button type="button" icon="pi pi-trash" className="p-button-danger" hidden={index === 0} onClick={() => arrayHelpers.remove(index)} />
                                                        </div>
                                                    </div>

                                                ))
                                            }
                                        </>
                                    )}
                                />
                            }
                            <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                                <CancelButton label={t("Cancel") + ''} onClose={onClose} />
                                <Button label={t("Prev") + ''} type="button" icon="pi pi-angle-double-left" className="p-button-text p-button-raised" onClick={() => { setStage(pre => pre - 1); }} disabled={stage === 0} />
                                <Button label={t("Next") + ''} type="button" icon="pi pi-angle-double-right" className="p-button-text p-button-raised" iconPos="right" onClick={() => { setStage(pre => pre + 1); }} disabled={stage === 1} />
                                <SaveButton label={id ? t("Update") as string : t("Save") as string} isSubmitting={isSubmitting} />
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default ItemsModal;



interface IItemsModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | undefined;
    updateTableData: any;
}