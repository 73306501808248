import React, { useEffect, useState } from "react";
import { Formik, Form  } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useDebounce from "../../../helpers/debounce";
import { customerSvc, itemsSvc, rentSvc } from "../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CancelButton from "../../../components/button/cancelButton";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import './rent.css'

const RentModal = ({ setVisible, visible = false, viewOnly = true, id, updateTableData }: any) => {
    const ini = {
        customer_id: "",
        tool_id: "",
        quantity: "",
        items: []
    };
    
    const [t] = useTranslation()
    const [initialValues, setInitialValues] = useState(ini);
    const [items, setItems] = useState<any>([]);
    const [customer, setCustomer] = useState([]);
    const [errorMsg, setErrorMsg] =useState<boolean>(false)
    const [itemId, setItemId] = useState(id);
    const [tool, setTool] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState('');
    const [customerSelected, setCustomerSelected] = useState(null);
    const [toolSelected, setToolSelected] = useState(null);
    const [customerSearch, setcustomerSearch] = useState<string>('');
    const [toolSearch, setToolSearch] = useState<string>('');
    const debouncedCustomerSearch = useDebounce<string>(customerSearch, 500);
    const debouncedToolSearch = useDebounce<string>(toolSearch, 500);

    useEffect(() => {
        if (debouncedCustomerSearch && debouncedCustomerSearch.length > 0)
            customerSvc.getAutoSuggest(customerSearch).then(res => {
                setCustomer(res.data);
            });
    }, [debouncedCustomerSearch]); // Fetch customer suggestions based on the debounced customer search value

    useEffect(() => {
        if (debouncedToolSearch && debouncedToolSearch.length > 0) {
            itemsSvc.getAutoSuggestForRent(debouncedToolSearch).then(res => {
                setTool(res.data);
            });
        }
    }, [debouncedToolSearch]); // Fetch tool suggestions based on the debounced tool search value


 // Get custimer name if id present
    useEffect(() => {
        if (id&&visible) {
            rentSvc.get(id).then(res => {
                customerSvc.getAutoSuggestById(res.data.customer_id).then(res => {
                    setCustomerSelected(res.data)
                })
                setInitialValues({ ...res.data });

            });
        }
    }, [itemId,visible]);
    


    const handleAddItem = (values: any) => {
        const { tool_id, quantity } = values;
        if (!tool_id || !quantity  ||quantity>availableQuantity||quantity<0) {
            // Display error message if tool ID or quantity is not provided
            setErrorMsg(true);
            return;
        }
        const newItem = {
            tool_id: values.tool_id.value, // Use the value (ID) of the selected tool
            tool_name: values.tool_id.name, // Include the name of the selected tool
            quantity: values.quantity
        };
        
        setItems([...items, newItem]);
        values.tool_id = '';
        setToolSelected(null)
        setAvailableQuantity('')
        values.quantity = "";
        setErrorMsg(false)
    };

    const handleDeleteItem = (itemId: any) => {
        const updatedItems = items.filter((item: any) => item.tool_id !== itemId);
        setItems(updatedItems);
    };

    const handleSubmit = async (values: any) => {
        const createData = {
            customer_id: values.customer_id,
            tools: items.map((item: any) => ({
                tool_id: item.tool_id,
                quantity: item.quantity
            }))
        };
        const updateData = {
            tools: items.map((item: any) => ({
                tool_id: item.tool_id,
                quantity: item.quantity
            }))
        };
        // Perform save or update operation based on the presence of an id
        if (id) {
            
            await rentSvc.update(id, updateData);// Update method in the rentSvc service
            toast.success("Update Success");
        } else {
            
            await rentSvc.save(createData); // Save method in the rentSvc service
            toast.success("Save Success");
        }
    
        // Reset form and close modal
        setCustomerSelected(null);
        setToolSelected(null);
        setcustomerSearch('');
        setToolSearch('');
        updateTableData();
        setItems([]);
        setVisible(false);
    };
    

    const onHide = () => {
        setItems([]);
        setVisible(false);
        setCustomerSelected(null);
        setToolSelected(null)
        setToolSearch('')
        setcustomerSearch('');
        setErrorMsg(false);
    };
    
      
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
            <Button label={id ? t("Update") as string : t("Save") as string} icon="pi pi-check" style={{ backgroundColor: '#11B3CF'}} className="p-button-success p-button-raised" type='submit' disabled={items.length === 0} />
        </div>);
    return (
        <Dialog header={!id ? t("Create Rent") as string : t("Add Rent") as string}visible={visible} style={{ width: "50vw" }} onHide={onHide}>
            <Formik initialValues={initialValues}  onSubmit={handleSubmit}>
                {({ values, handleChange, errors, getFieldProps, touched, setFieldValue  }) => (
                    <Form>
                        <div className="grid p-fluid mt-3">

                            <div className="field col-12 md:col-4">
                                <label htmlFor="customer_id">{t("Customer Name")}</label>
                                <AutoComplete field="name"  value={customerSelected} suggestions={customer} completeMethod={(e) => { setcustomerSearch(e.query); }} onChange={(e) => { setFieldValue("customer_id", e.value.value); setCustomerSelected(e.value); }}  disabled={id}/>
                                { errorMsg && !values.customer_id && <ErrorMessage message="Customer Name is required." id="customer_id" />}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="tool_id">Tool Name</label>                              
                                <AutoComplete field="name" value={toolSelected} suggestions={tool} completeMethod={(e) => setToolSearch(e.query)} 
                                onChange={(e) => {
                                    const selectedTool = e.value; // Get the selected tool object
                                    setFieldValue('tool_id', selectedTool);
                                    setToolSelected(selectedTool);
                                
                                    // Show available quantity to the user
                                    if (selectedTool.availabe) {
                                        setAvailableQuantity(selectedTool.availabe);
                                    } else {
                                        setAvailableQuantity(''); // Reset the available quantity if not available
                                    }
                                }}
                                 />
                                 
                                {errorMsg && !values.tool_id && <ErrorMessage message="Item Name required." id="tool_id" />}


                            </div>
                            <div className="field col-12 md:col-2">
                                <label htmlFor="quantity">Quantity</label>
                                <InputText id="quantity" type="number" name="quantity" onChange={handleChange} value={values.quantity} keyfilter={/^[0-9]\d*$/} />
                                {availableQuantity && (<p>Available: {availableQuantity}</p>)}
                                {errorMsg && !values.quantity && <ErrorMessage message="Quantity is required." id="quantity" />}
                                {errorMsg && <ErrorMessage message="Enter correct qty" id="tool_id" />}


                            </div>
                            <div className="field col-12 md:col-1 ">
                                <label htmlFor="add">Add</label>
                                <Button icon="pi pi-plus" type="button" onClick={() => handleAddItem(values)} />
                            </div>

                            <div className="col-12">

                                <DataTable
                                    value={items}
                                    // dataKey="_id"
                                    className="datatable-responsive white rentlist-datatable"
                                    emptyMessage="No items selected"
                                    responsiveLayout="scroll"
                                >
                                    <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header="Item Name" field="tool_name" ></Column>
                                    <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header="Quantity" field="quantity" ></Column>
                                    <Column style={{ width: '6rem' }} headerStyle={{ width: '6rem', textAlign: 'center' }} header="Action" body={(rowData, rowIndex) => (
                                        <Button icon="pi pi-trash" type="button" className="p-button-rounded p-button-danger" onClick={() => handleDeleteItem(rowData.tool_id)} />
                                    )}></Column>
                                </DataTable>

                            </div>




                        </div>
                        {DialogFooter}
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};



export default RentModal;




