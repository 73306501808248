import React, { useEffect, useState } from "react";
import { Formik, Form  } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useDebounce from "../../../helpers/debounce";
import {  itemsSvc, maintenanceSvc,  } from "../../../services";
import CancelButton from "../../../components/button/cancelButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { InputNumber } from "primereact/inputnumber";
import * as Yup from 'yup';

const MaintenanceModal = ({ setVisible, visible = false, viewOnly = true, id, updateTableData }: any) => {
    const ini = {
        tool_id: "",
        quantity: '',
        returned:'',
        lost:0,
        amount:'',
    };
    
    const [t] = useTranslation()
    const [initialValues, setInitialValues] = useState<any>(ini);
    const [errorMsg, setErrorMsg] =useState<boolean>(false)
    // const [itemId, setItemId] = useState(id);
    const [tool, setTool] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState('');
    const [balaceRtnQty, setBalaceRtnQty] = useState<any>();
    const [toolSelected, setToolSelected] = useState(null);
    const [toolSearch, setToolSearch] = useState<string>('');
    const debouncedToolSearch = useDebounce<string>(toolSearch, 500);

  

    useEffect(() => {
        if (debouncedToolSearch && debouncedToolSearch.length > 0) {
            itemsSvc.getAutoSuggestForRent(debouncedToolSearch).then(res => {
                setTool(res.data);
            });
        }
    }, [debouncedToolSearch]); // Fetch tool suggestions based on the debounced tool search value

    useEffect(() => {
        if (id&&visible) {
            maintenanceSvc.get(id).then(res => {
                itemsSvc.getAutoSuggestById(res.data.tool_id._id).then(res => {
                    setToolSelected(res.data)
                })
                const responce=res.data
                setBalaceRtnQty(responce.quantity-(responce.returned+responce.lost))
                setInitialValues(res.data);

            });
        }

    }, [id,visible]);
  
    const validationSchema = id
    ? Yup.object().shape({
        
        returned: Yup.number()
                .required('Required')
                .integer('Return Qty must be an integer')
                .typeError('Return Qty must be a number'),
        lost: Yup.number().typeError('Lost must be a number')
                .required('Required')
                .integer('Lost must be an integer'),
        amount: Yup.number().typeError('Amount must be a number').required('Required').integer('Amount must be an integer'),
      })
    : Yup.object().shape({
        quantity: Yup.number()
          .typeError('Quantity must be a number')
          .integer('Quantity must be an integer')
          .min(1, 'Quantity must be at least 1')
          .required('Quantity is required')
          .positive('Return Qty must be a positive number'),
      });
  
    const handleSubmit = async (values: any) => {
            const createData = {
            tool_id: values.tool_id.value, // Use the value (ID) of the selected tool
            quantity: values.quantity
        };
        const updateData = {
            _id: id,
            returned: values.returned,
            lost: values.lost,
            amount: values.amount
        };  
        // Perform save or update operation based on the presence of an id
        if (id) {
            if (updateData.returned === 0 && updateData.lost === 0)
            {
                toast.warning("Item can't return");
                return;
            }
               
            await maintenanceSvc.update(updateData);// Update method in the rentSvc service
            toast.success("Item return Success");
        } else {
             // Display error message if tool ID or quantity is not provided
          if (!createData.tool_id || createData.quantity > availableQuantity) {
            setErrorMsg(true);
            return;
          }
            await maintenanceSvc.save(createData);//  Save method in the rentSvc service
            toast.success("Save Success");
        }
    
        // Reset form and close modal
        setToolSelected(null);
        setToolSearch('');
        setInitialValues(ini);
        updateTableData();
        setVisible(false);
    };

    const onHide = () => {
        setVisible(false);
        setToolSelected(null)
        setInitialValues(ini);
        setToolSearch('')
    };
    
      
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
            <Button label={id ? t("Return") as string : t("Save") as string} icon="pi pi-check" style={{ backgroundColor: '#11B3CF'}} className="p-button-success p-button-raised" type='submit'  />
        </div>);
    return (
        <Dialog header={!id ? t("Add") as string : t("Return")}visible={visible} style={{ width: "50vw" }} onHide={onHide}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ values, handleChange, errors, getFieldProps, touched, setFieldValue  }) => (
                    <Form>
                        <div className="grid p-fluid mt-3">

                            
                            <div className="field col-12 md:col-4">
                                <label htmlFor="tool_id">Tool Name</label>                              
                                <AutoComplete field="name" value={toolSelected} suggestions={tool} completeMethod={(e) => setToolSearch(e.query)  } 
                                onChange={(e) => {
                                    const selectedTool = e.value; // Get the selected tool object
                                    setFieldValue('tool_id', selectedTool);
                                    setToolSelected(selectedTool);
                                
                                    // Show available quantity to the user
                                    if (selectedTool.availabe) {
                                        setAvailableQuantity(selectedTool.availabe);
                                    } else {
                                        setAvailableQuantity(''); // Reset the available quantity if not available
                                    }
                                }}
                                 disabled={id}/>
                                   {errors.tool_id && touched.tool_id && <ErrorMessage message={errors.tool_id} id="tool_id" />}
                                   {errorMsg && !values.tool_id && <ErrorMessage message="Item Name required." id="tool_id" />}


                            </div>
                            {!id &&
                            <div className="field col-12 md:col-2">
                                <label htmlFor="quantity">Quantity</label>
                                <InputNumber id="quantity" onChange={(e:any) => setFieldValue('quantity', e.value)} value={values.quantity} />
                                {availableQuantity && (<p>Available: {availableQuantity}</p>)}
                                {errors.quantity && touched.quantity && <ErrorMessage message={errors.quantity} id="quantity" />}
                                {errorMsg && !values.quantity && <ErrorMessage message="Quantity is required." id="quantity" />}
                                {errorMsg && values.quantity>availableQuantity && <ErrorMessage message="Choose available quantity" id="tool_id" />}
                            </div>}
                            
                           {id && ( <>
                           <div className="field col-12 md:col-2">
                                <label htmlFor="returned">Return Qty</label>
                                <InputNumber id="returned" onChange={(e:any) => setFieldValue('returned', e.value)} value={values.returned||null} />
                                {balaceRtnQty && (<p>Balance Qty: {balaceRtnQty}</p>)}
                                {errors.returned && touched.returned && <ErrorMessage message={errors.returned} id="returned" />}
                                {errorMsg  && <ErrorMessage message="Balance Qty" id="returned" />}

                            </div>
                            <div className="field col-12 md:col-2">
                                <label htmlFor="lost">Lost</label>
                                <InputNumber id="lost" onChange={(e:any) => setFieldValue('lost', e.value)} value={values.lost||0} />
                                {errors.lost && touched.lost && <ErrorMessage message={errors.lost} id="lost" />}

                            </div>
                            <div className="field col-12 md:col-2">
                                <label htmlFor="amount">Amount</label>
                                <InputNumber id="amount" onChange={(e:any) => setFieldValue('amount', e.value)} value={values.amount||null} />
                                {errors.amount && touched.amount && <ErrorMessage message={errors.amount} id="amount" />}
                                
                            </div>
                            </>)}

                        </div>
                        {DialogFooter}
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};



export default MaintenanceModal;




