import { axiosInstance } from '../interceptors/interceptors';


export const save = async (request: any) => {
    return await axiosInstance.post(`/rent/`, request);
};

export const list = async (limit: string, page: string) => {
    return await axiosInstance.get(`/rent/list?page=${page}&limit=${limit}`);
};

export const get = async (id: string) => {
    return await axiosInstance.get(`/rent/${id}`);
};
export const getDetails = async (id: string) => {
    return await axiosInstance.get(`/rent/getDetails/${id}`);
};

export const update = async (id: string, request: any) => {
    return await axiosInstance.put(`/rent/${id}`, request)
};

export const Delete = async (id: string) => {
    return await axiosInstance.delete(`/rent/${id}`);
}
export const multipleDelete = async (idList: any) => {
    return await axiosInstance.post(`/rent/deleteByIdList/`, idList);
}

export const returnItems=async (id: string | undefined, returnItems: any)=> {
    return await axiosInstance.post(`/rent/return/`,{rentdetail_id:id,tools:returnItems});
}
export const billList = async (limit: string, page: string,id: string) => {
    return await axiosInstance.get(`/rent/getBillList/${id}?page=${page}&limit=${limit}`);
};
export const getBillAmount = async (id: string) => {
    return await axiosInstance.get(`/rent/getAmount?id=${id}`);
};
export const getBillData = async (id: string) => {
    return await axiosInstance.get(`/rent/getBill?id=${id}`);
};
export const payBillAmnt = async ( request: any) => {
    return await axiosInstance.put(`/rent/payByBillId/`,request)
};

export const searchRent = async (name:any) => {
    return await axiosInstance.get(`/rent/search?name=${name}&page=${1}&limit=${10}`);
  };
  