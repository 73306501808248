import { Dialog } from 'primereact/dialog';
import { useTranslation } from "react-i18next";
import { Badge } from 'primereact/badge';
import './viewDetails.css'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from 'react';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';

interface IViewDetailsProps {
    setVisible: any,
    visible: any,
    details: any | undefined;
    tittle: string | undefined;
    tableData: any[] | undefined,
    tableDataColumns: any | undefined,
    extraAction: any | undefined,
    totalRecords?: number;
    paginationProps?(page: string, limit: string): void;

}

const ViewDetailsModal = (props: IViewDetailsProps) => {
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [t] = useTranslation()


    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
  setFirst(event.first);
  setLimit(event.rows);
  if (props.paginationProps) {
    props.paginationProps(event.page.toString(), event.rows.toString());
  }
};

    const statusBodyTemplate = (rawData: any) => {
        const status = rawData.status.toString();
        return <Badge value={status} severity={status === 'rented' ? 'success' : (status === 'partially returned' ? 'warning' : status === 'created' ? 'warning' : status === 'returned' ? 'danger' : status === 'paid' ? 'success' : status === 'unpaid' ? 'danger' : 'info')} />;
    };

    const formatDate = (value: any) => {
        return value.toLocaleDateString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const formatIndianRupee = (rawData: any, field: string) => {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2
        });
        return rawData[field] !== undefined ? formatter.format(rawData[field]) : "";

    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div className='flex ml-auto'>
                    {<props.extraAction rowData={rowData} />}
                </div>
            </>
        );
    };

    const dateBodyTemplate = (rawData: any, field: string) => {
        return rawData[field] ? formatDate(new Date(rawData[field])) : '';
    };
    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog header={props.tittle} visible={props.visible} style={{ width: '70vw' }} onHide={() => props.setVisible(false)}  >
                <div id="invoice-content">
                    <div className="invoice-wrapper">
                        <div className="invoice-content">
                            <div className="invoice-footer grid grid-nogutter">
                                <div className="col-12">
                                    <div className="invoice-table-2 grid grid-nogutter">
                                        <div className="col-6">
                                            {Object.keys(props.details).map(key => (
                                                <div className="invoice-table-col header-col capitalize" key={key}>
                                                    <span>{key}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-6">
                                            {Object.keys(props.details).map(key => (
                                                <div className="invoice-table-col content-col" key={key}>
                                                    {key === 'Email' ? (
                                                        <span>{props.details[key]}</span>
                                                    ) : (
                                                        <span className='capitalize'>{props.details[key]}</span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12">

                            {props.tableData && <DataTable
                                value={props.tableData}
                                // dataKey="_id"
                                className="datatable-responsive white custom-datatable"
                                emptyMessage="No data found."
                                responsiveLayout="scroll"
                            >
                                <Column selectionMode="multiple" frozen alignFrozen='left'></Column>
                                {props.tableDataColumns.map((col: any, i: any) => {
                                    return (
                                        col.type && col.type === 'date' ?
                                            <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={t(col.header)} body={(e) => dateBodyTemplate(e, col.field)}  />
                                            :
                                            (col.type === 'amount' ? <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)} body={(e) => formatIndianRupee(e, col.field)}  />
                                                : col.type === 'status' ? <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} body={statusBodyTemplate} header={t(col.header)}  />
                                                    : <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)}  />)
                                    );
                                })}
                                {props.extraAction ? <Column header={t("Action")}  className="action" body={actionBodyTemplate} alignHeader={'left'} style={{ minWidth: '150px' }} alignFrozen="right" frozen></Column> : null}



                            </DataTable>
                            }
                        {props.paginationProps &&<Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={props.totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>}


                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    )
}
export default ViewDetailsModal;
