import React from 'react';
import { Button } from 'primereact/button';

function CancelButton({ label, onClose }:any) {

  return (
    <Button
      label={label ? label : ''}
      type="button"
      icon="pi pi-times"
      className="p-button-danger p-button-raised"
      onClick={onClose}
    />
  );
}

export default CancelButton;