import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { profileSvc } from '../../../services'
import { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, getIn } from 'formik';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { toast } from 'react-toastify';
import { Badge } from 'primereact/badge';
import { getCookie } from '../../../utils/cookieUtils';
import '../../../assets/fonts/fonts.css'
import { useTranslation } from 'react-i18next';

const AboutProfile = ({ userId }: any) => {

  const initial = {
    email: '',
    phone: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    enable_login: '',
    address: [{
      country: '',
      state: '',
      city: '',
      district: '',
      pin_code: '',
      address_type: ''
    }]
  };
  const [editing, setEditing] = useState(false);
  const [userData, setUserData] = useState<any>('');
  const [initialValues, setInitialValues] = useState(initial)
  const [id, setId] = useState('');
  const [t] = useTranslation()

  const address_type = [{ label: 'Permanent', value: 'permanent' },
  { label: 'Current', value: 'current' },
  { label: 'Emergency', value: 'emergency' }];



  const profileDetails = () => {
    const user = getCookie("user")
    if (user) {
      const data = user
      const userId = data.user.id;
      profileSvc.get(userId)
        .then(res => {
          setUserData(res.data)
          setId(res.data._id)
        })
    }
  }
  
  const getDetails = () => {
    if (id) {
      profileSvc.getDetails(id)
        .then(res => {
          setEditing(true);
          if (res && res.data) {
            setUserData(res.data);
            setId(res.data._id);
            setInitialValues({ ...res.data, address: res.data.address.length === 0 ? initialValues.address : res.data.address });
            setEditing(true);
          }
        })
    }
  };
  

  useEffect(() => {
    profileDetails()
  }, []);




  const onSubmit = async (e: any, actions: any) => {
    if (id) {
      await profileSvc.update(id, e);
      profileDetails()
      toast.success("Updated");
      setEditing(false)
    }
    actions.setSubmitting(false);
  };


  return (
    <div className="container-xl px-4 mt-4">
      <div className="row">
        <div className="col-xl-5" style={{ height: "550px", width: "450px" }}>
          {/* Profile picture card */}
          <div className="card mb-4 mb-xl-3" >
            <div className="card-header">{t("Profile")}</div>

            <div className="card-body d-flex">

              <div >
                <img
                  className="img-account-profile rounded-circle m-2 "
                  src="http://bootdey.com/img/Content/avatar/avatar1.png"
                  alt="c"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
              <div className='ml-5'>
                <p style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'Raleway' }}>
                  {userData.display_name ||`${userData?.first_name} ${userData?.middle_name} ${userData?.last_name}`}</p>
                {/* <p style={{ fontSize: '16px' }} className='capitalize'><i className="pi pi-phone mr-2" style={{ color: "blue" }} />Status : {userData.status}</p> */}
                <Badge className='mb-2 capitalize' value={userData.status} severity="success"></Badge>
                <p style={{ fontSize: '16px',fontFamily: 'Raleway' }} ><i className="pi pi-envelope mr-2" style={{ color: "blue" }} />{userData.email}</p>
                {/* <p style={{ fontSize: '16px',fontFamily: 'Raleway' }} className='capitalize'><i className="pi pi-gender " />Gender : {userData.gender}</p> */}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="m-4">


                </div>
              </div>

            </div>
            <hr className="mt-0 m-3"></hr>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className="p-button-success p-button-raised justify-content-center m-4"  style={{ backgroundColor:'#11B3CF'}} onClick={getDetails}>
                {t("View Profile")}
              </Button>
            </div>
            
          </div>
        </div>
        {!editing ? null :
          <div className="col-xl-7">
            {/* Account details card */}
            <div className="card mb-4">
              <div className="card-header">{t("Account Details")}</div>
              <div className="card-body">
                <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
                  {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (
                      <Form>

                        {/* Form Row */}
                        <div className="grid p-fluid mt-3">
                          {/* Form Group (first name) */}
                          <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              <InputText type="text" id="first_name" {...getFieldProps(('first_name'))} name='first_name' />
                              <label htmlFor="first_name"  >First Name</label>
                            </span>
                            {touched.first_name && errors.first_name && <ErrorMessage message={errors.first_name} id="first_name" />}
                          </div>

                          <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              <InputText id="middle_name" {...getFieldProps(('middle_name'))} name='middle_name' />
                              <label htmlFor="middle_name">Middle Name</label>
                            </span>
                            {touched.middle_name && errors.middle_name && <ErrorMessage message={errors.middle_name} id="middle_name" />}
                          </div>

                          {/* Form Group (last name) */}
                          <div className="field col-12 md:col-4">
                            <span className="p-float-label ">
                              <InputText id="last_name" {...getFieldProps(('last_name'))} name='last_name' />
                              <label htmlFor="last_name">Last Name</label>
                            </span>
                            {touched.last_name && errors.last_name && <ErrorMessage message={errors.last_name} id="last_name" />}
                          </div>

                          {/* Form Group (first name) */}
                          <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              <InputText id="email" {...getFieldProps(('email'))} name='email' />
                              <label htmlFor="email">Email</label>
                            </span>
                            {touched.email && errors.email && <ErrorMessage message={errors.email} id="email" />}
                          </div>

                          <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              <InputMask mask="999 999-9999" id="phone"  {...getFieldProps(('phone'))} name='phone' />
                              <label htmlFor="phone">Phone</label>
                            </span>
                            {touched.phone && errors.phone && <ErrorMessage message={errors.phone} id="phone" />}
                          </div>

                          {/* Form Group (last name) */}
                          <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              
                              <InputText id="gender" {...getFieldProps(('gender'))} name='gender' className='capitalize' />
                            </span>
                            {touched.gender && errors.gender && <ErrorMessage message={errors.gender} id="gender" />}
                          </div>
                        </div>

                        <FieldArray
                          name="address"
                          render={arrayHelpers => (
                            <>
                              {
                                values.address.map((val, index) => (
                                  <div className="grid p-fluid mt-3" key={index}>
                                    <Divider align="left">
                                      <div className="inline-flex align-items-center">
                                        <i className="pi pi-user mr-2"></i>
                                        <span style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Raleway' }}>Address {index + 1}</span>
                                      </div>
                                    </Divider>
                                    <div className="field col-12 md:col-4">
                                      <span className="p-float-label">
                                        <InputText id={`address.${index}.country`}  {...getFieldProps((`address.${index}.country`))} name={`address.${index}.country`} />
                                        <label htmlFor={`address.${index}.country`}>Country</label>
                                      </span>
                                      {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                      <span className="p-float-label">
                                        <InputText id={`address.${index}.state`}  {...getFieldProps((`address.${index}.state`))} name={`address.${index}.state`} />
                                        <label htmlFor={`address.${index}.state`}>State</label>
                                      </span>
                                      {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                      <span className="p-float-label">
                                        <InputText id={`address.${index}.city`}  {...getFieldProps((`address.${index}.city`))} name={`address.${index}.city`} />
                                        <label htmlFor={`address.${index}.city`}>City</label>
                                      </span>
                                      {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                      <span className="p-float-label">
                                        <InputText id={`address.${index}.district`}  {...getFieldProps((`address.${index}.district`))} name={`address.${index}.district`} />
                                        <label htmlFor={`address.${index}.district`}>District</label>
                                      </span>
                                      {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}

                                    </div>
                                    <div className="field col-12 md:col-4">
                                      <span className="p-float-label">
                                        <InputText id={`address.${index}.pin_code`}  {...getFieldProps((`address.${index}.pin_code`))} name={`address.${index}.pin_code`} keyfilter="int" />
                                        <label htmlFor={`address.${index}.pin_code`}>Pin Code</label>
                                      </span>
                                      {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                      <span className="p-float-label">
                                        <Dropdown options={address_type} id={`address.${index}.address_type`} value={values.address[index].address_type} onChange={(e) => { setFieldValue(`address.${index}.address_type`, e.value); }} name={`address.${index}.address_type`} />
                                        <label htmlFor={`address.${index}.address_type`}>Address Type</label>
                                      </span>
                                      {errors.address?.at(index) && <ErrorMessage message={getIn(errors.address?.at(index), 'country')} id={`address.${index}.country`} />}
                                    </div>
                                    <div className="field col-12 md:col-4 ">
                                      <Button type="button" icon="pi pi-plus" className="p-button-primary mr-2" aria-label="add" onClick={() => arrayHelpers.push({ // Push a new empty address object
                                        country: '',
                                        state: '',
                                        city: '',
                                        district: '',
                                        pin_code: '',
                                        address_type: ''
                                      })} hidden={values.address.length > index + 1} disabled={!!errors.address} />
                                      <Button type="button" icon="pi pi-trash" className="p-button-danger" hidden={index === 0} onClick={() => arrayHelpers.remove(index)} />
                                    </div>
                                  </div>

                                ))
                              }</>
                          )}
                        />
                        {/* Save changes button */}
                        <Button className="p-button-danger p-button-raised mr-2" style={{height:'2.5rem'}} onClick={() => setEditing(false)}>{t('Discard')}</Button>
                        <Button type="submit" label={t("Update")as string} icon="pi pi-check" className="p-button-success p-button-raised" style={{ backgroundColor:'#11B3CF'}} loading={isSubmitting} />

                      </Form>
                    )
                  }
                </Formik>
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
}
export default AboutProfile;
