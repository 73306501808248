
import { axiosInstance } from '../interceptors/interceptors';


export const save = async (request: any) => {
  return await axiosInstance.post(`/customer/`, request);
};

export const list = async (limit: string, page: string) => {
  return await axiosInstance.get(`/customer/list?page=${page}&limit=${limit}`);
};

export const get = async (id:string) => {
  return await axiosInstance.get(`/customer/${id}`);
};

export const serchCustomer = async (name:any) => {
  return await axiosInstance.get(`/customer/search?name=${name}`);
};

export const getAll = async () => {
  return await axiosInstance.get(`/customer/getAll`);
};
export const getAutoSuggest = async (name:string) => {
  return await axiosInstance.post(`/customer/getAutoSuggest`,{name});
};

export const getAutoSuggestById = async (id:string) => {
  return await axiosInstance.get(`/customer/getAutoSuggestById?id=${id}`);
};

export const update = async (id: string, request: any) => {
  return await axiosInstance.put(`/customer/${id}`,request)
};

export const Delete = async (id:string) => {
  return await  axiosInstance.delete(`/customer/${id}`);
}
export const multipleDelete = async (idList:any) => {
  return await  axiosInstance.post(`/customer/deleteByIdList/`,idList);
}
