import './home.css'
import { Chart } from 'primereact/chart';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { dashboardSvc } from '../../../services';
import { formatCurrency } from '../../../components/styledcomponents';
import ReturnPendingList from './returnPendingList';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export interface IAppProps { }

export function Home(props: IAppProps) {

  const [selectedReport, setSelectedReport] = useState('monthly');
  const [chartData, setChartData] = useState<any>([]);
  const [count, setCount] = useState<any>('');
  const [t] = useTranslation()

  //For chart details
  const getChartDetails = () => {
    dashboardSvc.getChartDetails({ type: selectedReport }).then(res => {
      setChartData(res.data)
    });
  };

  //For get counts(customers, tools, etc...)
  const getDashboardDetails = () => {
    dashboardSvc.getDashboardDetails().then(res => {
      setCount(res.data)
    });
  };

  useEffect(() => {
    getDashboardDetails()
    getChartDetails()
  }, []);

  const chartDatas = {
    labels: chartData.map((item: any) => {
      const date = new Date(item.labels);
      let formattedDate = '';

      if (selectedReport === 'monthly') {
        formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: '2-digit' });
      } else {
        formattedDate = date.toLocaleDateString('en-US', { month: 'long' });
      }

      return formattedDate;
    }),

    datasets: [
      {
        label: selectedReport === 'monthly' ? 'This Month Sales' : 'This year sales',
        data: chartData.map((item: any) => item.data),
        backgroundColor: '#007be5',
      },
    ],
  };

  const reportOptions = [
    { label: 'This Month', value: 'monthly' },
    { label: 'This Year', value: 'yearly' },
  ];

  const handleReportChange = (e: any) => {
    setSelectedReport(e.value);
    dashboardSvc.getChartDetails({ type: e.value }).then(res => {
      setChartData(res.data)
    });
  };

  const CountCard = ({ to, title, count, iconClass }: any) => (
    <div className="col-12 lg:col-6 xl:col-3">
      <Link to={to}>
        <div className="card mb-0 count_card">
          <div className="flex justify-content-between mb-3">
            
            <div >
              <span className="block text-500 font-bold mb-3 text-white text-xl" >{title}</span>
              <div className="text-900 font-medium text-3xl">{count || 'N/A'}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
              <i className={iconClass}></i>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );

  return (

    <div className="home grid">

      <CountCard to="/app/stocks" title={t("Items")} count={count.tool_count} iconClass="pi pi-building text-blue-500 text-xl" />
      <CountCard to="/app/customers" title={t("Customers")} count={count.cus_count} iconClass="pi pi-users text-blue-500 text-xl" />
      <CountCard to="/app/rents" title={t("Rents")} count={count.rentedCount || count.rentedCount === 0 ? count.rentedCount : null} iconClass="pi pi-shopping-cart text-blue-500 text-xl" />
      <CountCard title={t("Revenue")} count={formatCurrency(count.amnt)} iconClass="pi pi-credit-card text-blue-500 text-xl" />

      


      {/* Return pending list component */}

      {/* <div className="col-12 xl:col-6">
        <ReturnPendingList />
      </div> */}


      {/* Revanue overview */}

      <div className="col-12 xl:col-6">
        <div className="chart-card">
          <h5>{t("Revenue Overview")}</h5>
          <div className="p-chart">
            <div>
              <Dropdown
                value={selectedReport}
                options={reportOptions}
                onChange={handleReportChange}
                placeholder="Select a report"
              />
            </div>

            <h6 className='mt-2'>{selectedReport === 'monthly' ? 'This Month Sales' : 'This year Sales'}</h6>
            <Chart type="bar" data={chartDatas} />
          </div>
        </div>
      </div>

    </div>
  );
}
