import  { useContext } from 'react';
import { MainLayout } from '../containers/layout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from '../containers/views/home/home';
import { AuthContext, LayoutProvider } from '../context';
import ItemsList from '../containers/views/items/itemsList';
import LoginPage from '../containers/views/auth/login';
import ForgotPassword from '../containers/views/auth/forgotpassword';
import CheckMail from '../containers/views/auth/checkMail';
import SetNewPaasword from '../containers/views/auth/setNewPassword';
import UserList from '../containers/views/users/usersList';
import CustomersList from '../containers/views/customers/customersList';
import MasterView from '../containers/views/master/masterView';
import RentList from '../containers/views/rent/rentList';
import StockList from '../containers/views/stock/stockList';
import ReturnList from '../containers/views/return/returnList';
import MaintenanceList from '../containers/views/maintenance/maintenanceList';
import ProfileView from '../containers/views/profile/profileView';


const Index = () => {

  const { isAuthenticated } = useContext(AuthContext);

    return (
      <LayoutProvider>
        <Routes>
        {isAuthenticated ? (
          <>
          <Route path="/app" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="admin" element={<MasterView />} />
            <Route path="users" element={<UserList />} />
            <Route path="customers" element={<CustomersList />} />
            <Route path="items" element={<ItemsList />} />
            <Route path="stocks" element={<StockList />} />
            <Route path="maintenance" element={<MaintenanceList />} />
            <Route path="rents" element={<RentList />} />
            <Route path="returns" element={<ReturnList />} />
            <Route path="profile" element={<ProfileView />} />
            </Route>
            {/* <Route  path='/login'  element={<Navigate to='/app' replace />} /> */}
            </>
           
        ): null }

        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/checkmail" element={<CheckMail />} />
        <Route path="/setnewpassword" element={<SetNewPaasword />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </LayoutProvider>
  );
};

export default Index;
