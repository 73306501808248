import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import {  useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import useDebounce from "../../../helpers/debounce";
import {  itemsSvc, stockSvc, } from "../../../services";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import CancelButton from "../../../components/button/cancelButton";
import { stockSave } from "../../../validations";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";




const StockModal = ({ setVisible, visible = false, viewOnly = true, id,updateTableData }: IStockModal) => {
    const initial:Istock = {
        tool_id: "",
        rent_type: "",
        rent: 0,
        quantity: 0,
        purchased_on:'',
        cost:0,
    };
    const [initialValues, setInitialValues] = useState<Istock>(initial);
    const [t] = useTranslation()
    const [itemId, setItemId] = useState<string |undefined>(id);
    const [tool, setTool] = useState([]);
    const [toolSelected, setToolSelected] = useState(null);
    const [toolSearch, setToolSearch] = useState<string>('');
    const debouncedToolSearch = useDebounce<string>(toolSearch, 500);


    useEffect(() => {
        if (debouncedToolSearch && debouncedToolSearch.length > 0) {
            itemsSvc.getAutoSuggest(debouncedToolSearch).then(res => {
                setTool(res.data);
            });
        }
    }, [debouncedToolSearch]); // Fetch tool suggestions based on the debounced tool search value
    useEffect(() => {
        if (id && visible) {
            stockSvc.getToolDetails(id).then(res => {
                itemsSvc.getAutoSuggestById(id).then(res => {
                    setToolSelected(res.data)
                })
                setInitialValues({ ...res.data });
                
            });
        }
    }, [id, visible]);

    const rent_type = [{ label: 'Hourly', value: 'hourly' },
    { label: 'Daily', value: 'daily' }];

    const onSubmit = async (e:Istock,formikHelpers: FormikHelpers<Istock>) => {
        const res = id ? await stockSvc.update(id,e) : await stockSvc.save(e);

        toast.success("Success");
        updateTableData()
        setToolSelected(null);
        setToolSearch('');
        // formikHelpers.resetForm();
        setInitialValues(initial)
        setVisible(false);
    };

    const onHide = () => {
        setInitialValues(initial);
        setItemId(undefined);
        setToolSelected(null);
        setToolSearch('');
        setVisible(false);
    };
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
            <Button label={id ? t("Update") as string : t("Save") as string} style={{ background: '#11B3CF' }} icon="pi pi-check" className="p-button-success p-button-raised" type='submit' />
        </div>);
    return (
        <Dialog header={t("Add Stock")} visible={visible} maximizable style={{ width: '40vw' }} onHide={onHide}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={stockSave} onSubmit={onSubmit}>
                {({ values, errors, getFieldProps, touched, setFieldValue }) => (
                    <Form>

                        <div className="grid p-fluid mt-3">

                            <div className="field col-12 md:col-4">
                                <label htmlFor="tool_id" className="label-raleway-font">{t("Tool Name")}</label>
                                <AutoComplete field="name" value={toolSelected} suggestions={tool} completeMethod={(e) => { setToolSearch(e.query); }} onChange={(e) => { setFieldValue("tool_id", e.value.value); setToolSelected(e.value); }} disabled={!!id} />
                                {touched.tool_id && errors.tool_id && <ErrorMessage message={errors.tool_id} id="customer_id" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="cost" className="label-raleway-font">{t("Cost")}</label>
                                <InputNumber id="cost" minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('cost', e.value)} value={values.cost || 0} name='cost' />
                                {touched.cost && errors.cost && <ErrorMessage message={errors.cost} id="cost" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="purchased_on" className="label-raleway-font">{t("Purchased on")}</label>
                                <Calendar
                                    id="purchased_on"
                                    name="purchased_on"
                                    value={values.purchased_on}
                                    onChange={(e) => setFieldValue('purchased_on', new Date(e.value as Date))}
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                    className="w-full" />
                                {touched.purchased_on && errors.purchased_on && <ErrorMessage message={errors.purchased_on} id="purchased_on" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="Quantity">Quantity</label>
                                    <InputText id="quantity" {...getFieldProps(('quantity'))} name='quantity'  disabled={!!id}/>
                                {touched.quantity && errors.quantity && <ErrorMessage message={errors.quantity} id="quantity" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="rent_type" className="label-raleway-font">{t("Rent Type")}</label>
                                <Dropdown {...getFieldProps(('rent_type'))} name='rent_type' options={rent_type}
                                    id="rent_type" className="w-full" />
                                {touched.rent_type && errors.rent_type && <ErrorMessage message={errors.rent_type} id="rent_type" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="currency-india">Rent Amount</label>
                                    <InputText  id="rent" {...getFieldProps(('rent'))} name='rent' />
                                {touched.rent && errors.rent && <ErrorMessage message={errors.rent} id="rent" />}
                            </div>
                        </div>
                        {DialogFooter}
                    </Form>)}
            </Formik>

        </Dialog>
    );
};
export default StockModal;

interface IStockModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id?: string;
    updateTableData: any
}

interface Istock  {
    tool_id: string,
    rent_type: string,
    rent: number,
    quantity: number,
    purchased_on:string,
    cost:number,
};