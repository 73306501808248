import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { itemsSvc, userSvc } from '../../../services';
import ItemsModal from "./itemsModal";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import ViewDetailsModal from "../../../components/modal/viewDetails/viewDetails";
import ActionButton from "../../../components/button/actionButton";
import SearchBar from "../../../components/common/searchbar/searchBar";
import ViewDetailsV2 from "../../../components/modal/viewDetails/viewDetailsV2";




const ItemList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [t] = useTranslation()
  const[values,setValues]=useState<any>('')
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'tool_name', header: 'Tool Name' },
    { field: 'category', header: 'Category' },
    // { field: 'rent', header: 'Rent Amount', type:'amount' },
  ];


  const itemData = () => {
    itemsSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  useEffect(() => {
    itemData()
  }, [limit, page]);

  const handleStatusUpdate = (e: any, id: string,newStatus:any) => {
    // const newStatus = e.target.value;
    // itemsSvc.statusUpdate(id, newStatus).then(res => {
    //   itemData();
    // });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details = (id: string) => {
    itemsSvc.get(id).then(res => {
      setValues(res.data);
    });
    setviewDetails(true);
  }

  const ToolDetails: any = {
    "Tool Id": values.sl_no?values.sl_no:"N/A",
    "Tool Name": values.tool_name?values.tool_name:"N/A",
    "quantity":values.quantity?values.quantity:"N/A",
    "Rent Type":values.rent_type?values.rent_type:"N/A",
    "Rent Amount":values.rent?values.rent:"N/A",
    "Description": values.short_description?values.short_description:"N/A",
};
if (values.measurements) {
  values.measurements.forEach((measurements: any, index: number) => {
    ToolDetails[`Magnitude${index + 1}`] = measurements.magnitude || "N/A";
    ToolDetails[`Value ${index + 1}`] = measurements.value || "N/A";
    ToolDetails[`Unit ${index + 1}`] = measurements.unit || "N/A";
  });
}





//   const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteRecord = (i: string) => {
    itemsSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Item deleted successfully');
        itemData();
      } else {
        toast.error('Failed to delete item');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    itemsSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Items deleted successfully');
        itemData();
      } else {
        toast.error('Failed to delete items');
      }
    });
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );
  const extraAction = ({ rowData }: any) => {
    // const hasViewPermission = permissionList && permissionList.includes('pages.user.get');
    // const hasUpdatePermission = permissionList && permissionList.includes('pages.user.update');
    // const hasDeletePermission = permissionList && permissionList.includes('pages.user.delete');
    return <>
      {  (<ActionButton label={t("View")+''} icon="pi pi-eye" className="p-button-secondary" onClick={() => { details(rowData._id) }} />)}
      { (<ActionButton label={t("Update")+''} icon="pi pi-pencil" className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      { (<ActionButton label={t("Delete")+''} icon="pi pi-trash"  className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };

        // Implement Search bar 
        const handleSearch = () => {
          itemsSvc.searchTool(searchQuery).then(res => {
            if (res) {
              setTableData(res.data.data);
              settotalRecords(res.data.recordcount);
            } 
          });
      
        };
      
        const handleInputChange = (e: any) => {
          const value = e.target.value;
          setSearchQuery(value);
          if (value === '') {
            itemData()
          }
        };
        const itemSearch = () => {
      
          return (
            <React.Fragment>
              <SearchBar
                searchQuery={searchQuery}
                handleInputChange={handleInputChange}
                handleSearch={handleSearch}
              />
            </React.Fragment>
          );
        };
        
  return (
    <>
      <DataGrid tittle={t("Items") as string} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={handleStatusUpdate} searchBar={itemSearch}/>
      {visible &&<ItemsModal id={id} setVisible={setVisble} visible={visible} updateTableData={itemData} />}
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>
      {viewDetails && <ViewDetailsV2  setVisible={setviewDetails} visible={viewDetails} details={null} tittle={values?.tool_name} values={values} />}

    </>
  );
};

export default ItemList;