import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import {permissionsSvc} from '../../../../services';
import { toast } from "react-toastify";


const UserPermission = ({ setVisible, visible = false, id }: IRoleModal) => {
    const [available, setPermissions] = useState([]);
    const [userid, setId] = useState(id);
    const [selectedKey, setSelectedKey] = useState<TreeCheckboxSelectionKeys | null>(null);
    
    useEffect(() => {

        permissionsSvc.getAvailablePermiisions().then((res: any) => {
            setPermissions(res.data);
        });
        permissionsSvc.getUserPermiisions(userid).then(res => {
            setSelectedKey(res.data.inline.reduce((acc: any, cur: any) => ({ ...acc, [cur]: { checked: true } }), {}));
        });

    }, []);

    const onSubmit = async () => {
        if (selectedKey) {
            const res = await permissionsSvc.updateUserPermiisions({ userId: userid, permissionList: Object.keys(selectedKey) });
            if (res)
            setVisible(false)
            toast.success("Permissions Updated");
        }

    };
    const DialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setVisible(false)}} />
            <Button label={!id ? 'Update' : 'Save'} icon="pi pi-check" className="p-button-text" onClick={onSubmit} />
        </>);
    return (
        <Dialog header="Add Permission" footer={DialogFooter} visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <Tree className="p-tree-horizontal capitalize" value={available} selectionMode="checkbox" selectionKeys={selectedKey} onSelectionChange={(e: any) => { setSelectedKey(e.value); }} />
        </Dialog>
    );
};

export default UserPermission;



interface IRoleModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string;
}