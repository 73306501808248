import * as Yup from 'yup';

export const itemsSave = Yup.object().shape({
    tool_name: Yup.string().required('Required'),
    category: Yup.string().required("Required"),
    // measurements: Yup.array().of(
    //     Yup.object().shape({
    //         magnitude: Yup.string().required('Required'),
    //         value: Yup.string().required('Required'),
    //         unit: Yup.string().required('Required'),
    //     })
    // )
});