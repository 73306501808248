import { axiosInstance } from "../interceptors/interceptors";

export const list = async (limit: string, page: string) => {
    return await axiosInstance.get(`/tools/getStock?page=${page}&limit=${limit}`);
  };

  export const save = async (request: any) => {
    return await axiosInstance.post(`/inventory/`, request);
  };

  export const update = async (id: string, request: any) => {
    return await axiosInstance.put(`/inventory?tool_id=${id}`,request)
  };

  export const addStock = async (request: any) => {
    return await axiosInstance.put(`/inventory/addStock`,request)
  };
  export const getToolDetails = async (id:string) => {
    return await axiosInstance.get(`/inventory/getToolDetails/${id}`);
  };

  export const searchTool = async (name:string) => {
    return await axiosInstance.get(`/inventory/search/${name}?page=${1}&limit=${10}`);
  };

