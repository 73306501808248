import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { useState } from "react";
import { Steps } from 'primereact/steps';
import {  Form, Formik, getIn } from "formik";
import { customerSave } from "../../../validations";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { customerSvc } from '../../../services';
import { toast } from "react-toastify";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import CancelButton from "../../../components/button/cancelButton";
import SaveButton from "../../../components/button/saveButton";
const CustomerModal = ({ setVisible, visible = false, id, updateTableData }: ICustomerModal) => {
    const ini = {
        first_name: '',
        middle_name: '',
        last_name: '', 
        email: '',
        phone: '',
        address: {
            country: '',
            state: '',
            city: '',
            district: '',
            pin_code: '',
            address_name: ''
        }
    };
    const [stage, setStage] = useState(0);
    const [custId, setCustId] = useState(id);
    const [initialValues, setInitialValues] = useState(ini);
    const [t] = useTranslation()

    const items = [
        {
            label: 'Basics'
        },
        {
            label: 'Address'
        }
    ];
    const onSubmit = async (e: any, actions: any) => {
        if (custId) {
            await customerSvc.update(custId, e);
            toast.success("Updated");
            updateTableData();
            onClose();
        }
        else {
            await customerSvc.save(e);
            toast.success("Created");
            updateTableData();
            onClose();

        }
       
        actions.setSubmitting(false);
    };
    useEffect(() => {
        setCustId(id);
        if (id && visible) {
            customerSvc.get(id).then(res => setInitialValues({ ...res.data, address: res.data.address.length === 0 ? initialValues.address : res.data.address }));
        }
    }, [id, visible]);
    const onClose = () => {
        setInitialValues(ini);setCustId(undefined);  setStage(0);setVisible(false); 
    };
   
    return (
        <Dialog header={!id ? t("Create Customer")as string : t("Update Customer")as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onClose}>
            <Steps model={items} className="my-3 pb-3" activeIndex={stage} />
            <Formik enableReinitialize initialValues={initialValues} validationSchema={customerSave} onSubmit={onSubmit}>
                {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (
                        <Form>
                            {stage === 0 && <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText type="text" id="first_name" {...getFieldProps(('first_name'))} name='first_name' />
                                        <label htmlFor="First Name"  >First_Name</label>
                                    </span>
                                    {touched.first_name && errors.first_name && <ErrorMessage message={errors.first_name} id="first_name" />}
                                    
                                </div>

                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText id="middle_name" {...getFieldProps(('middle_name'))} name='middle_name' />
                                        <label htmlFor="middle_name">Middle Name</label>
                                    </span>
                                    {touched.middle_name && errors.middle_name && <ErrorMessage message={errors.middle_name} id="middle_name" />}
                                </div>
                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label ">
                                        <InputText id="last_name" {...getFieldProps(('last_name'))} name='last_name' />
                                        <label htmlFor="last_name">Last Name</label>
                                    </span>
                                    {touched.last_name && errors.last_name && <ErrorMessage message={errors.last_name} id="last_name" />}
                                </div>

                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputText id="email" {...getFieldProps(('email'))} name='email' />
                                        <label htmlFor="email">Email</label>
                                    </span>
                                    {touched.email && errors.email && <ErrorMessage message={errors.email} id="email" />}
                                </div>
                                <div className="field col-12 md:col-4">
                                    <span className="p-float-label">
                                        <InputMask mask="999 999-9999" id="phone"  {...getFieldProps(('phone'))} name='phone' />
                                        <label htmlFor="phone">Phone</label>
                                    </span>
                                    {touched.phone && errors.phone && <ErrorMessage message={errors.phone} id="phone" />}
                                </div>



                            </div>}
                            {
                                stage === 1 &&
                                <div className="grid p-fluid mt-3" >
                                    <div className="field col-12 md:col-4">
                                        <span className="p-float-label">
                                            <InputText id={`address.address_name`}  {...getFieldProps((`address.address_name`))} name={`address.address_name`} />
                                            <label htmlFor={`address.address_name`}>Address Name</label>
                                        </span>
                                        {errors.address&& <ErrorMessage message={getIn(errors.address, 'address_name')} id={`address.address_name`} />}

                                        
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <span className="p-float-label">
                                            <InputText id={`address.country`}  {...getFieldProps((`address.country`))} name={`address.country`}/>
                                            <label htmlFor={`address.country`}>Country</label>
                                        </span>
                                        {errors.address&& <ErrorMessage message={getIn(errors.address, 'country')} id={`address.country`} />}

                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <span className="p-float-label">
                                            <InputText id={`address.state`}  {...getFieldProps((`address.state`))} name={`address.state`} />
                                            <label htmlFor={`address.state`}>State</label>
                                        </span>
                                        {errors.address&& <ErrorMessage message={getIn(errors.address, 'state')} id={`address.state`} />}

                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <span className="p-float-label">
                                            <InputText id={`address.city`}  {...getFieldProps((`address.city`))} name={`address.city`} />
                                            <label htmlFor={`address.city`}>City</label>
                                        </span>
                                        {errors.address&& <ErrorMessage message={getIn(errors.address, 'city')} id={`address.city`} />}

                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <span className="p-float-label">
                                            <InputText id={`address.district`}  {...getFieldProps((`address.district`))} name={`address.district`} />
                                            <label htmlFor={`address.district`}>District</label>
                                        </span>
                                        {errors.address&& <ErrorMessage message={getIn(errors.address, 'district')} id={`address.district`} />}


                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <span className="p-float-label">
                                            <InputText id={`address.pin_code`}  {...getFieldProps((`address.pin_code`))} name={`address.pin_code`} keyfilter="int" />
                                            <label htmlFor={`address.pin_code`}>Pin Code</label>
                                        </span>
                                        {errors.address&& <ErrorMessage message={getIn(errors.address, 'pin_code')} id={`address.pin_code`} />}

                                    </div>
                                   
                                </div>

                            }
                            <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                                <CancelButton label={t("Cancel") + ''} onClose={onClose}/>
                                <Button label={t("Prev")+''} icon="pi pi-angle-double-left" className="p-button-text p-button-raised" onClick={() => { setStage(pre => pre - 1); }} disabled={stage === 0} />
                                <Button label={t("Next")+''} icon="pi pi-angle-double-right" className="p-button-text p-button-raised" iconPos="right" onClick={() => { setStage(pre => pre + 1); }} disabled={stage === 1} />
                                <SaveButton label={id ? t("Update") as string : t("Save") as string} isSubmitting={isSubmitting} />

                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default CustomerModal;



interface ICustomerModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | undefined;
    updateTableData: any;
}