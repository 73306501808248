import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from 'primereact/button';
import moment from 'moment';
import logo from '../../../assets/comptivia-newlogo.png'
import { useTranslation } from 'react-i18next';



interface IPdfExporterProps {
    tittle: string;
    data: any[];
    columns: Array<{
        field: string,
        header: string;
        type?: string;
    }>;
    report?: any[];
}
declare module 'jspdf' {
    interface jsPDF {
        autoTable: (options: any) => jsPDF;
    }
}

const PdfExporter = (props: IPdfExporterProps) => {
    const exportPdf = () => {
    const doc = new jsPDF();
    const curentDate = moment().format('DD MMM YYYY');
    doc.setFontSize(8);
    doc.text("Date :" + curentDate, 180, 15);

    // Add company name and address to the PDF
    const companyName = "Comptivia Technologies";
    const companyAddress = [
        "2nd floor, Infra Futura, Opp BMC,",
        "Seaport Airport Road, Kakkanad,",
        "Ernakulam - 682039"
    ];
      
    // Add the company logo below the company name
    const logoWidth = 40; // Adjust the logo width as needed
    const logoHeight = 8; // Adjust the logo height as needed
    const logoX = 12; // X position where the logo should start
    const logoY = 7; // Y position where the logo should start
    doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

    
    
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    // doc.text(companyName, 14, 15);
    doc.setTextColor(0);
    doc.setFontSize(10);
    doc.text(companyAddress, 14, 20);

    // Calculate the center position of the page
    const pageWidth = doc.internal.pageSize.getWidth();
    const headingText = props.tittle; 
    const headingFontSize = 16; 
    const headingWidth = doc.getStringUnitWidth(headingText) * headingFontSize / doc.internal.scaleFactor;
    const headingX = (pageWidth - headingWidth) / 2;

    // Add the heading to the center position on the page
    doc.setFontSize(headingFontSize);
    doc.text(headingText, headingX, 45);

if(props.report){
    const reportStartY = 60; 
    const reportLineHeight = 6;
    const columnWidth = 50; 

    doc.setDrawColor(0, 0, 0); // Set border color for the table
    doc.setFillColor(41, 128, 186); // Set background color for the table header
    doc.setFontSize(12);
    doc.rect(14, reportStartY, columnWidth * 2, reportLineHeight, 'FD'); // Draw the table header rectangle
    doc.setTextColor(255, 255, 255);
    doc.text('Report', 15, reportStartY + 5); // Table header text

    doc.setFontSize(10);
    doc.setTextColor(0);
    props.report.forEach((item, index) => {
        doc.rect(14, reportStartY + ((index + 1) * reportLineHeight), columnWidth, reportLineHeight, 'S'); // Draw table row borders (1st column)
        doc.text(item.key, 15, reportStartY + ((index + 1) * reportLineHeight) + 5); // Table content (1st column)

        doc.rect(14 + columnWidth, reportStartY + ((index + 1) * reportLineHeight), columnWidth, reportLineHeight, 'S'); // Draw table row borders (2nd column)
        doc.text(String(item.value), 15 + columnWidth, reportStartY + ((index + 1) * reportLineHeight) + 5); // Table content (2nd column)
    });


}


   // Add the table to the PDF
    const header = props.columns.map((col) => col.header);
    const data = props.data.map((item) =>
        props.columns.map((col) =>
            col.type === 'date'
                ? dateBodyTemplate(item, col.field) // Use dateBodyTemplate for date fields
                : typeof item[col.field] === 'string'
                    ? item[col.field].charAt(0).toUpperCase() + item[col.field].slice(1) // Convert to capitalize format
                    : item[col.field]
        )
    );

    const startY=props.report?90:50;

    doc.autoTable({
        head: [header],
        body: data,
        startY: startY,
    });

    
    // doc.save('download.pdf');
    // Open the PDF in a new window or iframe

    const pdfDataUri = doc.output('datauristring');
    const pdfWindow = window.open();
    pdfWindow?.document.write(`<iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`);
    };

    const formatDate = (value: any) => {
        return value.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const dateBodyTemplate = (rawData: any, field: string) => {
        return formatDate(new Date(rawData[field]));
    };
    const [t] = useTranslation()

    return (
        
        <Button label={t("Download") as string}style={{ backgroundColor: '#11B3CF', height: '35px' }} tooltip='Report' tooltipOptions={{ position: "bottom" }}
            icon="pi pi-file-pdf" className="p-button-success  mr-2 " onClick={exportPdf} />
    );
};

export default PdfExporter;
