import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { InputSwitch } from 'primereact/inputswitch';
import { Badge } from 'primereact/badge';
import './datagrid.css'
import Heading from '../styledcomponents/heading';
import PdfExporter from '../common/pdfExporter/pdfExporter';

interface IDataGridProps {
    tittle: string;
    data: any[];
    totalRecords: number;
    columns: Array<{
        field: string,
        header: string;
        type?: string;
    }>;
    extraAction: any | undefined
    paginationProps(page: string, limit: string): void;
    onCreateOrEdit(id?: string): void;
    deleteByIdList: any |undefined;
    handleStatusUpdate?: any | undefined
    hideButtons?: boolean;
    heading?: string;
    searchBar?:any
    report?:any

}

const DataGrid = (props: IDataGridProps) => {
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [id] = useState<string | undefined>();
    const [selected, setSelected] = useState([]);
    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation()

    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        props.paginationProps(event.page.toString(), event.rows.toString());
    };
    const multipleDelete = () => {
        const selectedIds = (selected as { _id: string }[]).map(item => item._id);
        if (selectedIds.length ) {
            props.deleteByIdList({ idList: selectedIds });
        }
        
    };

    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };

    const handleStatusChange = (e: any, id: string) => {
        const newStatus = e.value ? 'active' : 'terminated';
        props.handleStatusUpdate(e, id, newStatus);
    };
    // To show status in switch
    const statusBodyTemplate = (rawData: any) => {
        return (
            <>
                <div className="p-field-switch statusBodyTemplate">
                    <InputSwitch
                        checked={rawData.status === 'active'}
                        onChange={(e: any) => handleStatusChange(e, rawData._id)}
                    />
                </div>
            </>
        );
    };

    const emailTemplate = (rawData: any) => {
        return (
            <>
                <div >
                    {rawData.email}
                </div>
            </>
        );
    };

    // To show status in badge
    const badgeStatusBodyTemplate = (rawData: any) => {
        const txt = rawData.status.toString();
        return <Badge value={rawData.status} severity={txt === 'rented' ? "success" : (txt === 'partially returned' ? "warning" : txt === 'created' ? 'warning' : txt === 'returned' ? 'danger' : 'info')} />;
    };
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div className='flex'>
                    {<props.extraAction rowData={rowData} />}
                </div>
            </>
        );
    };

    const leftToolbarTemplate = () => {
        // if (props.hideButtons) {
        //     return (
        //       <h4>{props.heading}</h4>
        //     );
        //   }
        return (
            <React.Fragment>
                <div className="flex">
                        {props.searchBar && <props.searchBar />}
                        {props.deleteByIdList && <Button label={t("Delete") + ''} icon="pi pi-trash" className="p-button-danger p-button-raised p-button-sm ml-3" onClick={() => { setDeleteDialog(true) }} disabled={!selected || !selected.length} style={{ display: selected && selected.length >= 2 ? 'inline-block' : 'none', backgroundColor: '#11B3CF', border: 'none' }} />}
                    </div>


            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        if (props.hideButtons) {
            return
            (
                <h4>{props.heading}</h4>
            );
        }
        return (
            <React.Fragment>
                <Button label={t("Add New") + ' '+props.tittle} icon="pi pi-plus" style={{ backgroundColor: '#11B3CF'}} className="p-button-success p-button-raised p-button-sm mr-2" onClick={() => { props.onCreateOrEdit(); }} />
                {/* <Button icon="pi pi-file-excel" className="p-button-success  p-button-raised p-button-sm" style={{ backgroundColor: '#11B3CF' }} onClick={exportCSV} /> */}
                {props.data && props.data.length > 0 && <PdfExporter tittle={props.tittle} columns={props.columns} data={props.data} report={props.report} />}

            </React.Fragment>
        );
    };
    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { !id && multipleDelete(); setDeleteDialog(false) }} />
        </>
    );



    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no" >
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className="mx-1 pagination-no">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div><Heading>{t(props.tittle)}</Heading></div>
                <div className="common-header-toolbar"><div>{leftToolbarTemplate()} </div><div>{rightToolbarTemplate()}</div></div>
                <DataTable
                    ref={dt}
                    value={props.data}
                    sortField="createdAt"
                    sortOrder={-1}
                    selection={selected}
                    onSelectionChange={(e: any) => setSelected(e.value)}
                    dataKey="_id"
                    className="datatable-responsive custom-datatable"
                    globalFilter={globalFilter}
                    emptyMessage={t("No data found")}
                    // header={header}
                    // scrollDirection="vertical"
                    scrollable
                    scrollHeight="400px"
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '4rem' }} frozen alignFrozen='left'></Column>

                    {props.columns.map((col, i) => {
                        return (
                            col.type && col.type === 'date' ?
                                <Column key={`date-${i}`} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={col.header}  />
                                :
                                col.type === 'status' ? <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} alignHeader={"center"} body={statusBodyTemplate} header={t(col.header)}  />
                                    : col.type === 'email' ? <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} alignHeader={"center"} body={emailTemplate} header={t(col.header)}  />
                                        : col.type === 'badgestatus' ? <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} className='capitalize' alignHeader={"left"} body={badgeStatusBodyTemplate} header={t(col.header)}  />
                                            : <Column key={`badgestatus-${i}`} style={{alignSelf:'center', flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} className='capitalize' field={col.field} header={t(col.header)}  />
                        );
                    })
                    }
                    {props.extraAction ? <Column header={t("Actions")}  className="action" body={actionBodyTemplate}  style={{ textAlign: 'center', flexGrow: 1, flexBasis: '150px',minWidth: '150px' }} alignHeader="left"  alignFrozen="right" frozen></Column> : null}

                </DataTable>
                <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={props.totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

                <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default DataGrid;
