import { axiosInstance } from "../interceptors/interceptors";


export const list = async (page: Number, limit: Number) => {
    return await axiosInstance.get(`users/userslist?page=${page}&limit=${limit}`);
};
// export const roleList = async () => {
//     return await axiosInstance.get(`admin/role/list`);
// };
export const roleList = async () => {
    return await axiosInstance.get(`admin/getAllRolesNameAndId`);
};

export const saveRole = async (userid:string,role:string[]) => {
    return await axiosInstance.post(`users/addRolePermission`,{userId:userid,roles:role});
};
export const getUserRoles = async (id:string) => {
    return await axiosInstance.get(`users/getAvailableRole/${id}`);
};
export const getUserPermiisions = async (id:string) => {
    return await axiosInstance.get(`users/listInlinePermission/${id}`);
};
export const getAvailablePermiisions = async () => {
    return await axiosInstance.get(`users/getAvailablePermission`);
};
export const updateUserPermiisions = async (req:any) => {
    return await axiosInstance.post(`/users/updateInlinePermission`,req);
};

export const getUserPermissions =  (id:string) => {
    return  axiosInstance.get(`admin/getAllPermissions/${id}`);
};