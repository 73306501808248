import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import  {categorySvc} from '../../../../services';


const CategoryModal = ({ setVisible, visible = false, viewOnly = true, id,updateTableData }: IRoleModal) => {
    const [categoryId] = useState(id);
    const [category, setCategory] = useState("");
    const [categoryError, setCategoryError] = useState(''); //show error message if category empty

    useEffect(() => {
        if (id) {
            categorySvc.get(id).then(res => {
                setCategory(res.data.category_name);
            });
        }
    }, [id]);
    const onSubmit = async () => {

        //show error message if category empty
        if (!category) {
            setCategoryError('Category is required');
            return;
          }
          setCategoryError('');
        //Category update
        if (categoryId) {
            const res = await categorySvc.update(category, categoryId);
            if (res) toast.success("Updated");
            setVisible(false)
            updateTableData()
            return;
        }
        //Category save
        const res = await categorySvc.save({category_name:category});
            if (res) toast.success("Created");
            updateTableData()
            setVisible(false)
            return;

    };
    
    const DialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => {setVisible(false) }} />
            <Button label={id ? 'Update' : 'Save'} icon="pi pi-check" style={{ backgroundColor: '#11B3CF'}} className="p-button-success" onClick={onSubmit} />
        </>);
    return (
        <Dialog header={id ? 'Update Category' : 'Add Category'} footer={DialogFooter} visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-column gap-2" style={{ maxWidth: 400 }}>
                <label htmlFor="category_name">Category</label>
                <InputText id="category_name" value={category} aria-describedby="category_name-help" onChange={(e) => {setCategory(e.target.value);setCategoryError('')}} className={categoryError ? 'p-invalid' : ''} />
                {categoryError && <ErrorMessage id="category_name-help" message={categoryError} />}
            </div>
        </Dialog>
    );
};

export default CategoryModal;



interface IRoleModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | undefined;
    updateTableData:any;
}