import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { forgotpasswordValidation } from '../../../validations';
import { Formik, Form, FormikHelpers } from 'formik';
import { forgotPassword } from '../../../services';
import { useNavigate,Link } from 'react-router-dom';
import { StyledH3 } from '../../../components/styledcomponents';

function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues = { user_name: '' };
  const onSubmit = async (e: any, actions: FormikHelpers<{
    user_name: string;
  }>) => {
    const res = await forgotPassword(e);
    if (res)
      navigate('/checkmail',{state:{userIdentity:res.data.userIdentity}});
  };


  return (
    <Formik initialValues={initialValues} validationSchema={forgotpasswordValidation} onSubmit={onSubmit}>
      {
        ({ isSubmitting, errors, getFieldProps, touched, isValid }) => (<Form>
          <div className='surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden' >
            <div className="flex flex-column align-items-center justify-content-center">
              <div style={{ borderRadius: '56px', padding: '0.3rem', }}>
                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                  {/* <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">FMT</div>
                    <span className="text-600 font-medium">Forgot Password ?</span>
                  </div> */}
                  <StyledH3 className="fmt-forgot-password-title">Reset Password</StyledH3>
                  <hr></hr>
                  <div>
                  <p className="oxd-text oxd-text--p" >Please enter your username to identify your account to reset your password</p>
                  </div>
                  <div>
                    <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="email1" className="auth-label ">
                          <i className="pi pi-user mr-2"></i>
                            Username
                          </label>
                      <InputText id="email1" aria-describedby='username-help' type="text" className={` ${touched.user_name && errors.user_name ? 'p-invalid' : ''}`} style={{ padding: '1rem' }} {...getFieldProps(('user_name'))} name='user_name' />
                      {touched.user_name && errors.user_name && <ErrorMessage message={errors.user_name} id="username-help" />}
                    </div>
                    <hr></hr>
          
                    
                    <Button label={isSubmitting ? '' : 'Reset Password'} className="w-full p-2 text-md" type='submit' loading={isSubmitting} />
                  </div>
                  <div className="flex align-items-center justify-content-between mb-5 gap-5">
                      
                      <Link to="/login" className="font-medium no-underline ml-auto text-right cursor-pointer mt-3" style={{ color: 'var(--primary-color)' }}>Back to Login ?</Link>

                    </div>
                </div>
              </div>
            </div>
          </div>
        </Form>)
      }
    </Formik>

  );
}

export default ForgotPassword;
