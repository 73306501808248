import React, { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { permissionsSvc } from '../../../../services';
import { useTranslation } from 'react-i18next';
import UserPermission from './userpermision';
import UserRolePermission from './userRolePermission';
import '../master.css'
import ActionButton from '../../../../components/button/actionButton';
import Heading from '../../../../components/styledcomponents/heading';
import InputGroup from 'react-bootstrap/esm/InputGroup';


const PermissionList = () => {
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [totalRecords, setTotal] = useState(0);
    const [visible, setVisible] = useState(false);
    const [visiblePermission, setVisiblePermission] = useState(false);
    const [visibleRolePermission, setVisibleRolePermission] = useState(false);
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t, i18n] = useTranslation()

    const getUsers = () => {
        permissionsSvc.list((page + 1), limit).then((res: any) => {
            setUsersList(res.data.user);
            setTotal(res.data.recordcount);
        });
    };

   
    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };

   

    const confirmDeleteProduct = (product: any) => {
        setDeleteDialog(true);
    };
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton icon="pi pi-user" label={t("Add Role")+''}  className="p-button-secondary" onClick={() => { setId(rowData._id); setVisibleRolePermission(true)}} />
                <ActionButton icon="pi pi-plus" label={t("Add Permission")+''}  className="p-button-secondary" onClick={() => { setId(rowData._id); setVisiblePermission(true) }} />
            </>
        );
    };
   

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-5">
            <div>
            <Heading>{t('Users')}</Heading>
            </div>
            <div className="search-bar">
                        <InputGroup>
                            
                            <InputText
                                // value={searchQuery}
                                
                                placeholder="Search..."
                                style={{width:'300px'}}
                            />
                            <Button label={t("Search")+''} icon="pi pi-search" style={{ backgroundColor: '#11B3CF'}} className='p-button-success'  />
                        </InputGroup>
                    </div>
            
        </div>
    );
    
    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        setPage(event.page);
    };
    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        setVisible(false);
        if (id)
            setId(id);
        setVisible(true);

    };
    useEffect(() => {
        getUsers();
    }, [page, limit]);
    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);
    return (
        <React.Fragment>
            <div className="grid crud-demo">
                <div className="col-12">
                <div >{header}</div>
                    <DataTable
                        ref={dt}
                        value={usersList}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive master-datatable"
                        globalFilter={globalFilter}
                        emptyMessage="No data found."
                        // responsiveLayout="scroll"
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Name")} field='display_name' ></Column>
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Email")} field='email' ></Column>
                        <Column header={t("Action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>
                    <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

                    
                </div>
            </div>
            {visiblePermission && <UserPermission visible={visiblePermission} setVisible={setVisiblePermission} id={id} />}
            {visibleRolePermission && <UserRolePermission visible={visibleRolePermission} setVisible={setVisibleRolePermission} id={id} />}

        </React.Fragment>
    );
};

export default PermissionList;