
import { TabMenu } from 'primereact/tabmenu';
import  { useEffect, useState } from 'react';
import AboutProfileList from './aboutProfile';
import ChangePassword from './changePassword';
import './profile.css'


const ProfileView = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const items = [
        { label: 'About', icon: 'pi pi-fw pi-id-card' },
        { label: 'Change Password', icon: 'pi pi-lock' },
    ];

    return (
        <>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); }} className='profile-tabmenu' />
            {activeIndex == 0 && <div className="mt-5">
                <AboutProfileList />
            </div>}
            {activeIndex == 1 && <div className="mt-5">
                <ChangePassword />
            </div>}
            
        </>
    );
};
export default ProfileView;