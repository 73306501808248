import * as Yup from 'yup';

export const stockSave = Yup.object().shape({
    tool_id: Yup.string().required('Required'),
    rent_type: Yup.string().required("Required"),
    rent: Yup.number().required('Required').positive("Required").typeError('Required'),
    quantity: Yup.number().required("Required").positive("Required").typeError('Required'),
    // .transform((value, originalValue) => {
    //     return parseInt(originalValue);
    //   }).moreThan(0, 'Quantity must be above zero'),
    
});

export const stockAddQuantity = Yup.object().shape({
    quantity: Yup.number().required("Required").positive("Required").typeError('Required'),
    // .transform((value, originalValue) => {
    //     return parseInt(originalValue);
    //   }).moreThan(0, 'Quantity must be above zero'),
});